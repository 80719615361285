/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { FC, useEffect, useState } from "react";
import { AgentData } from "../../store/api/model/AgentData";
import Tile from "../tiles/Tile";

import "../../App.css";

import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapLocation,
  faClone,
  faExternalLink,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useMsal } from "@azure/msal-react";
import PropTypes from "prop-types";
import CreateUserAction from "./CreateUserAction";
import CreateRequestTicketAction from "./CreateRequestTicketAction";
import FavoritenListe from "../favourites/FavoritenListe";
import { useFavorites } from "../favourites/FavoritesContext";
import FloatingHeadline from "../global/headlines/FloatingHeadline";
// import SpecialModalAd from "../SpecialModalAd";

interface CopyAddressComponentProps {
  name: string;
}

interface TileData {
  uuid: string;
  name: string;
  href: string;
  logoSrc: string;
  isNew?: boolean;
  requestActionName?: string;
  category: string;
  badgeText?: string;
  badgeType?: "warning" | "info" | "error";
}

const lambdaDataSecret: string = process.env.REACT_APP_LAMBDA_DATA_SECRET as string;
const lambdaDataUrl: string = process.env.REACT_APP_LAMBDA_DATA_URL as string;

const CopyAddressComponent: FC<CopyAddressComponentProps> = ({ name }) => {
  const [copySuccess, setCopySuccess] = useState("");
  const { t } = useTranslation();

  const address = `Thinkport GmbH
  ${name}  
  Arnsburger Straße 74
  60385 Frankfurt (Main)`;

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(address);
      setCopySuccess("in Zwischenablage kopiert √");
      setCopySuccess(t("copySuccess"));
    } catch (err) {
      setCopySuccess(t("copyError"));
    }
  };

  return (
    <div className="text-center">
      <button className="px-4 py-2 m-2 bg-blue-500" data-tip={t("copyHint")} onClick={copyToClipboard}>
        <FontAwesomeIcon icon={faClone} />
      </button>

      {copySuccess && (
        <p className="bg-green-200 shadow-sm rounded-sm text-green-700 p-1" role="alert">
          <p>{copySuccess}</p>
        </p>
      )}
    </div>
  );
};

CopyAddressComponent.propTypes = {
  name: PropTypes.string.isRequired,
};

export interface AgentsListProps {
  entries?: AgentData[];
}

function AddressComponent() {
  const { t } = useTranslation();
  const [content, setContent] = useState("Xaddresses");

  useEffect(() => {
    const addressesDiv = document.getElementById("Xaddresses");
    const mailboxesDiv = document.getElementById("postadressen");
    const thirdOptionDiv = document.getElementById("invoiceadressen");

    if (addressesDiv) {
      addressesDiv.style.display = content === "Xaddresses" ? "block" : "none";
    }
    if (mailboxesDiv) {
      mailboxesDiv.style.display = content === "postadressen" ? "block" : "none";
    }
    if (thirdOptionDiv) {
      thirdOptionDiv.style.display = content === "invoiceadressen" ? "block" : "none";
    }
  }, [content]);

  return (
    <div>
      <div className="flex justify-center">
        <button
          className={`tab btn btn-xs hover:bg-primary new-border-radius text-white mr-3 ${
            content === "Xaddresses" ? "bg-primary" : "bg-black"
          }`}
          onClick={() => setContent("Xaddresses")}
        >
          {t("generalAdresses")}
        </button>

        <button
          className={`tab btn btn-xs hover:bg-primary new-border-radius text-white mr-3 ${
            content === "postadressen" ? "bg-primary" : "bg-black"
          }`}
          onClick={() => setContent("postadressen")}
        >
          {t("sendingAdresses")}
        </button>
        <button
          className={`tab btn btn-xs hover:bg-primary new-border-radius text-white mr-3 ${
            content === "invoiceadressen" ? "bg-primary" : "bg-black"
          }`}
          onClick={() => setContent("invoiceadressen")}
        >
          {t("invoiecAdress")}
        </button>
      </div>
    </div>
  );
}

export default function AgentsList(props: AgentsListProps): JSX.Element {
  const [tilesData, setTilesData] = useState<TileData[]>([]);
  const { ids } = useFavorites(); // Hole die aktuellen Favoriten-IDs

  const supportTiles = tilesData.filter(tile => tile.category === "support");
  const recentlyTiles = tilesData.filter(tile => tile.category === "recently");
  const collaborationTiles = tilesData.filter(tile => tile.category === "collaboration");
  const bookingsTiles = tilesData.filter(tile => tile.category === "bookings");
  const benefitsTiles = tilesData.filter(tile => tile.category === "benefits");

  const { entries } = props;
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();
  const userName = accounts[0] && accounts[0].name;
  const userEmail = accounts[0] && accounts[0].username.toString();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setisError] = useState(false);
  const getRandomCount = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setisError(false);
      try {
        const response = await fetch(`${lambdaDataUrl}?password=${lambdaDataSecret}`);
        if (!response.ok) {
          setisError(true);
          throw new Error("Netzwerkantwort war nicht ok");
        } else {
          const data = (await response.json()) as TileData[];
          setTilesData(data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Fehler beim Laden der Daten:", error);
        setisError(true);
        setIsLoading(false);
      }
    };

    void fetchData();
  }, []);

  if (entries?.length === 0) {
    return (
      <div className="mt-5">
        <h3>{t("noResults")}</h3>
      </div>
    );
  }

  const SkeletonTile = () => (
    <div className="max-w card-border-lg overflow-hidden card-bg-color p-6 hover:cursor-pointer flex flex-col items-center justify-center relative animate-pulse">
      <div className="rounded-full bg-gray-300" style={{ width: "100px", height: "100px" }}></div>
      <div className="text-center mt-2">
        <span
          className="inline-block bg-gray-300 text-blue-900 font-bold text-sm"
          style={{ width: "50px", height: "20px" }}
        ></span>
      </div>
    </div>
  );

  return (
    <>
      <div className={"p-10"}>
        <br />
        <br />
        <br />
        <br />

        {/* <SpecialModalAd
          delay={5000}
          reappearHours={3}
          imageSrc="https://images-internal-thinkport-space-tiles.s3.eu-central-1.amazonaws.com/images/ads/modal-ad-new-timebooking.gif"
        /> */}

        {/* {accountInfo && authorizedBetaUser.includes(accountInfo.localAccountId) && ( */}
        {/* )} */}
        <div style={{ marginBottom: "5em" }}>
          <CreateUserAction />
        </div>

        <div role="alert" className="alert alert-warning mt-8 text-xl">
          <FontAwesomeIcon className="text-error" icon={faInfoCircle} />
          <span className="font-bold">
            Bitte entschuldigt unvollständige/fehlerhafte Styles bei der Theme Auswahl &quot;Thinkport&quot;. Daran wird
            demnächst weitergearbeitet.
          </span>
        </div>

        {ids.length > 0 && (
          <>
            <h1 id="favourites" className="text-5xl mt-8 font-bold leading-none text-center">
              <FloatingHeadline>{t("favourites")}</FloatingHeadline>
            </h1>
            {isError && (
              <div className="mt-4 flex justify-center items-center h-screen">
                <h3>
                  <div className="alert alert-error font-bold">
                    <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} /> Error while fetching tiles
                  </div>
                </h3>
              </div>
            )}

            {isLoading ? (
              <div className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-4 mb-6">
                {Array.from({ length: getRandomCount(2, 5) }).map((_, index) => (
                  <SkeletonTile key={index} />
                ))}
              </div>
            ) : (
              <FavoritenListe tilesData={tilesData} />
            )}
          </>
        )}

        <h1 id="support" className="text-5xl mt-8 font-bold leading-none text-center">
          <FloatingHeadline>{t("support")}</FloatingHeadline>
        </h1>
        {isError && (
          <div className="mt-4 flex justify-center items-center h-screen">
            <h3>
              <div className="alert alert-error font-bold">
                <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} /> Error while fetching tiles
              </div>
            </h3>
          </div>
        )}

        <div className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {isLoading
            ? Array.from({ length: getRandomCount(2, 5) }).map((_, index) => <SkeletonTile key={index} />)
            : supportTiles.map(tile => (
                <Tile
                  uuid={tile.uuid}
                  key={tile.name}
                  href={tile.href}
                  logoSrc={tile.logoSrc}
                  isNew={tile.isNew}
                  requestActionName={tile.requestActionName}
                  badgeText={tile.badgeText}
                  badgeType={tile.badgeType}
                />
              ))}
        </div>

        <div className="relative top-20 h-20"></div>
        <h1 id="recently" className="text-5xl mt-16 mb-2 font-bold leading-none text-center">
          <FloatingHeadline>{t("recently")}</FloatingHeadline>
        </h1>
        {isError && (
          <div className="mt-4 flex justify-center items-center h-screen">
            <h3>
              <div className="alert alert-error font-bold">
                <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} /> Error while fetching tiles
              </div>
            </h3>
          </div>
        )}

        <div className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {isLoading
            ? Array.from({ length: getRandomCount(2, 5) }).map((_, index) => <SkeletonTile key={index} />)
            : recentlyTiles.map(tile => (
                <Tile
                  uuid={tile.uuid}
                  key={tile.name}
                  href={tile.href}
                  logoSrc={tile.logoSrc}
                  isNew={tile.isNew}
                  requestActionName={tile.requestActionName}
                  badgeText={tile.badgeText}
                  badgeType={tile.badgeType}
                />
              ))}
        </div>

        <h1 id="addresses" className="text-5xl mt-16 mb-2 font-bold leading-none text-center">
          <FloatingHeadline>{t("addresses")}</FloatingHeadline>
        </h1>

        {isLoading ? (
          <div className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-4">
            {Array.from({ length: getRandomCount(2, 5) }).map((_, index) => (
              <SkeletonTile key={index} />
            ))}
          </div>
        ) : (
          <>
            <AddressComponent />
            <br />
            <span id="Xaddresses">
              <div className="grid grid-cols-4 gap-4">
                <div className="card max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 flex items-center justify-center">
                  <figure className="card-figure">
                    <img
                      style={{ width: "100%", height: "auto", objectFit: "cover" }}
                      src="https://media.istockphoto.com/id/186367581/photo/frankfurt-germany.jpg?s=612x612&w=0&k=20&c=88fRkMlLA8pRWnfagc4OBY3GXAIKeaQFiTA9zN0Dr1k="
                      alt="Frankfurt"
                    />
                  </figure>
                  <div className="card-body">
                    <h1 className="card-title justify-center">
                      <div className="badge badge-xl">
                        <a href="https://www.google.com/maps/place/Arnsburger+Str.+74,+60385+Frankfurt+am+Main/@50.125182,8.7046104,17z/data=!3m1!4b1!4m6!3m5!1s0x47bd0eecb563bf17:0x18c811748aafac46!8m2!3d50.1251786!4d8.7067991!16s%2Fg%2F11bw403lcl">
                          FRANKFURT <FontAwesomeIcon icon={faMapLocation} />
                        </a>
                      </div>
                    </h1>
                    <p className="text-center">
                      Thinkport Headquarter
                      <br /> Arnsburger Straße 74 (Hinterhaus) <br /> 60385 Frankfurt (Main)
                    </p>
                    <p className="text-center mt-2">
                      <b>Ansprechpartner:&nbsp;</b>
                      <a className="hover:cursor-pointer hover:text-green-500" href="mailto:aortner@thinkport.digital">
                        Alex Ortner
                      </a>
                    </p>
                    <div className="card-actions justify-center">
                      <a
                        href="https://thinkport-digital.atlassian.net/wiki/spaces/COM/pages/2825420813/Zugang+und+Schl+ssel"
                        className="tab btn btn-xs btn-dark hover:shadow-lg text-white mr-3 items-center"
                      >
                        Dokumentation für Zugang &nbsp; <FontAwesomeIcon icon={faExternalLink} />
                      </a>
                      <CreateRequestTicketAction email={userEmail} platform="Nuki + SwitchBot" />
                    </div>
                  </div>
                </div>

                <div className="card max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 flex items-center justify-center">
                  <figure className="card-figure">
                    <img
                      style={{ width: "100%", height: "auto", objectFit: "cover" }}
                      src="https://assets.adac.de/image/upload/ar_16:9,c_fill,f_auto,g_auto,q_auto:eco,w_1500/v1/ADAC-eV/KOR/Bilder/PR/sehenswuerdigkeiten-leipzig-skyline-2203_vslokk.jpeg"
                      alt="Leipzig"
                    />
                  </figure>
                  <div className="card-body">
                    <h1 className="card-title justify-center">
                      <div className="badge badge-xl">
                        <a href="https://www.google.de/maps/place/SimpliOffice+Coworking+Space+%26+Eventlocation+Leipzig/@51.3368225,12.3718825,16z/data=!3m2!4b1!5s0x47a7acea9db0dd55:0xe17a9406b0a79c63!4m6!3m5!1s0x47a6f9cd49586985:0xa208624a9bd59bee!8m2!3d51.3368225!4d12.3744574!16s%2Fg%2F11gjs9l5_l?entry=ttu&g_ep=EgoyMDI1MDEyOS4xIKXMDSoASAFQAw%3D%3D">
                          LEIPZIG <FontAwesomeIcon icon={faMapLocation} />
                        </a>
                      </div>
                    </h1>
                    <p className="text-center">
                      SimpliOffice COWORKING
                      <br /> Markgrafenstraße 2 <br /> 04109 Leipzig
                    </p>

                    <p className="text-center mt-2">
                      <b>Ansprechpartner:&nbsp;</b>
                      <a className="hover:cursor-pointer hover:text-green-500" href="mailto:pdangelo@thinkport.digital">
                        Philipp D&apos; Angelo
                      </a>
                    </p>
                    <div className="card-actions justify-center">
                      <a
                        href="https://thinkport-digital.atlassian.net/wiki/spaces/COM/pages/2328657938/How+to...+Basislager#Keys"
                        className="tab btn btn-xs btn-dark hover:shadow-lg text-white mr-3 items-center"
                      >
                        Dokumentation für Zugang &nbsp; <FontAwesomeIcon icon={faExternalLink} />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="card max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 flex items-center justify-center">
                  <figure className="card-figure">
                    <img
                      src="https://d3c80vss50ue25.cloudfront.net/media/filer_public_thumbnails/filer_public/10/e3/10e3c6cc-819f-4827-90c5-a0a7bb31fa2e/220311_elbphilharmonie_c_thies_raetzke.jpg__686x387_q85_crop_subject_location-960%2C640_subsampling-2_upscale.jpg"
                      alt="Hamburg"
                    />
                  </figure>
                  <div className="card-body">
                    <h1 className="card-title justify-center">
                      <div className="badge badge-xl">
                        <a href="https://www.google.com/maps/place/Arnsburger+Str.+74,+60385+Frankfurt+am+Main/@50.125182,8.7046104,17z/data=!3m1!4b1!4m6!3m5!1s0x47bd0eecb563bf17:0x18c811748aafac46!8m2!3d50.1251786!4d8.7067991!16s%2Fg%2F11bw403lcl">
                          HAMBURG <FontAwesomeIcon icon={faMapLocation} />
                        </a>
                      </div>
                    </h1>
                    <p className="text-center">
                      Unicorn Workspaces Sprinkenhof
                      <br /> Burchardstraße 14
                      <br /> 20095 Hamburg
                    </p>
                    <p className="text-center mt-2">
                      <b>Ansprechpartner:&nbsp;</b>
                      <a
                        className="hover:cursor-pointer hover:text-green-500"
                        href="mailto:ptolstych@thinkport.digital"
                      >
                        Philipp Tolstych
                      </a>
                    </p>
                    <div className="card-actions justify-center">
                      <a
                        href="https://thinkport-digital.atlassian.net/wiki/spaces/COM/pages/2518056972/Hamburg#T%C3%BCr-%C3%B6ffnen-via-App"
                        className="tab btn btn-xs btn-dark hover:shadow-lg text-white mr-3 items-center"
                      >
                        Dokumentation für Zugang &nbsp; <FontAwesomeIcon icon={faExternalLink} />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="card max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 flex items-center justify-center">
                  <figure className="card-figure">
                    <img
                      src="https://www.arcotel.com/files-sbbasic/sr_arcotel-group/berliner-fernsehturm.jpeg?w=720&h=720"
                      alt="Berlin"
                    />
                  </figure>
                  <div className="indicator">
                    <span className="indicator-item badge badge-warning font-bold">Neue Adresse seit 01.02.2024</span>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title justify-center">
                      <div className="badge badge-xl">
                        <a href="https://www.google.com/maps/place/Arnsburger+Str.+74,+60385+Frankfurt+am+Main/@50.125182,8.7046104,17z/data=!3m1!4b1!4m6!3m5!1s0x47bd0eecb563bf17:0x18c811748aafac46!8m2!3d50.1251786!4d8.7067991!16s%2Fg%2F11bw403lcl">
                          BERLIN <FontAwesomeIcon icon={faMapLocation} />
                        </a>
                      </div>
                    </h1>
                    <p className="text-center">
                      Unicorn Workspaces Gendarmenmarkt
                      <br /> Französische Straße 24 <br /> 10117 Berlin (Mitte)
                    </p>
                    <p className="text-center mt-2">
                      <b>Ansprechpartner:&nbsp;</b>
                      <a className="hover:cursor-pointer hover:text-green-500" href="mailto:crau@thinkport.digital">
                        Clemens Rau
                      </a>
                    </p>
                    <div className="card-actions justify-center">
                      <a
                        href="https://thinkport-digital.atlassian.net/wiki/x/BgBfsQ"
                        className="tab btn btn-xs btn-dark hover:shadow-lg text-white mr-3 items-center"
                      >
                        Dokumentation für Zugang &nbsp; <FontAwesomeIcon icon={faExternalLink} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span id="postadressen">
              <div className="grid grid-cols-4 gap-4  in-card-text">
                <div className="max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 hover:cursor-pointer flex items-center justify-center">
                  <div className="max-w-sm">
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase hover:cursor-pointer hover:text-green-500">
                      <a href="https://www.google.com/maps/place/Arnsburger+Str.+74,+60385+Frankfurt+am+Main/@50.125182,8.7046104,17z/data=!3m1!4b1!4m6!3m5!1s0x47bd0eecb563bf17:0x18c811748aafac46!8m2!3d50.1251786!4d8.7067991!16s%2Fg%2F11bw403lcl">
                        Frankfurt <FontAwesomeIcon icon={faMapLocation} />
                      </a>
                    </p>
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase"></p>
                    <div className="mt-6">
                      <p className="text-center">
                        {userName} <br />
                        c/o Thinkport GmbH
                        <br /> Arnsburger Straße 74 <br /> 60385 Frankfurt (Main)
                      </p>
                    </div>
                  </div>
                </div>
                <div className="max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 hover:cursor-pointer flex items-center justify-center">
                  <div className="max-w-sm">
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase hover:cursor-pointer hover:text-green-500">
                      <a href="https://www.google.de/maps/place/SimpliOffice+Coworking+Space+%26+Eventlocation+Leipzig/@51.3368225,12.3718825,16z/data=!3m2!4b1!5s0x47a7acea9db0dd55:0xe17a9406b0a79c63!4m6!3m5!1s0x47a6f9cd49586985:0xa208624a9bd59bee!8m2!3d51.3368225!4d12.3744574!16s%2Fg%2F11gjs9l5_l?entry=ttu&g_ep=EgoyMDI1MDEyOS4xIKXMDSoASAFQAw%3D%3D">
                        Leipzig <FontAwesomeIcon icon={faMapLocation} />
                      </a>
                    </p>
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase"></p>
                    <div className="mt-6">
                      <p className="text-center">
                        {userName} <br />
                        c/o SimpliOffice / Thinkport GmbH
                        <br /> Markgrafenstraße 2
                        <br /> 04109 Leipzig
                      </p>
                    </div>
                  </div>
                </div>
                <div className="max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 hover:cursor-pointer flex items-center justify-center">
                  <div className="max-w-sm">
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase hover:cursor-pointer hover:text-green-500">
                      <a href="https://www.google.com/maps?rlz=1C5GCEM_enDE1033DE1033&sxsrf=APwXEdcls4CBX4bk06VmiZ2LPHTa-FjtUw:1685445549345&uact=5&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIHCAAQDRCABDIGCAAQHhANMggIABAFEB4QDTIICAAQBRAeEA0yCAgAEAUQHhANMggIABAIEB4QDTIICAAQCBAeEA0yCAgAEAgQHhANMggIABAIEB4QDTIICAAQCBAeEA06BwgjELADECc6CggAEEcQ1gQQsAM6CggAEIoFELADEEM6DQgAEOQCENYEELADGAE6EgguEIoFENQCEMgDELADEEMYAjoHCCMQigUQJzoHCAAQigUQQzoLCC4QxwEQrwEQywE6BQgAEIAEOgUIABDLAToICAAQigUQkQI6CwguEK8BEMcBEMsBOhkILhCvARDHARDLARCXBRDcBBDeBBDgBBgDOggIABAWEB4QDzoICAAQigUQhgM6BQghEKABSgQIQRgAUC9Y2yFgzyRoAXABeACAAdABiAGzB5IBBTYuMi4xmAEAoAEBwAEByAER2gEGCAEQARgJ2gEGCAIQARgI2gEGCAMQARgU&um=1&ie=UTF-8&daddr=Burchardstra%C3%9Fe+14,+20095+Hamburg&geocode=FSMXMQMd1p-YACljBT61z4-xRzFtXvhqFxagbA&f=d&saddr&iwstate1=dir:to&fb=1&gad=1&gclid=EAIaIQobChMInL_8p_ac_wIV2AGLCh3uOwswEBAYASABEgLTHfD_BwE&ei=Etx1ZI2xHJqRrATxhrfgCA">
                        Hamburg <FontAwesomeIcon icon={faMapLocation} />
                      </a>
                    </p>
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase"></p>
                    <div className="mt-6">
                      <p className="text-center">
                        Thinkport ({userName}) <br />
                        c/o Unicorn
                        <br /> Burchardstraße 14 <br /> 20095 Hamburg
                      </p>
                    </div>
                  </div>
                </div>
                <div className="max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 hover:cursor-pointer flex items-center justify-center">
                  <div className="max-w-sm">
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase hover:cursor-pointer hover:text-green-500">
                      <a href="https://www.google.com/maps/place/WeWork/@52.506369,13.373303,15z/data=!4m6!3m5!1s0x47a851596a5063d7:0x689b1fca81e7297c!8m2!3d52.506369!4d13.373303!16s%2Fg%2F11ghfds375">
                        Berlin <FontAwesomeIcon icon={faMapLocation} />
                      </a>
                    </p>
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase"></p>
                    <div className="mt-6">
                      <p className="text-center">
                        {userName} <br />
                        c/o Unicorn Workspaces
                        <br /> Französische Straße 24 <br /> 10117 Berlin
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </span>
            <span id="invoiceadressen">
              <div className="flex justify-center alert gradient-secondary new-border-radius" role="alert">
                <span className="block sm:inline">{t("invoicewarning")}</span>
              </div>

              <br />
              <div className="grid grid-cols-5 gap-4">
                <div></div>
                <div></div>
                <div className="max-w card-border-lg overflow-hidden card-bg-color p-30 md:p-6 hover:cursor-pointer flex items-center justify-center">
                  <div className="max-w-sm">
                    <p className="text-center text-gray-900 font-bold text-xl mb-2 uppercase"></p>
                    <div>
                      <p className="text-center  in-card-text">
                        Thinkport GmbH
                        <br />
                        {userName}
                        <br /> Arnsburger Straße 74 <br /> 60385 Frankfurt (Main)
                      </p>
                      <CopyAddressComponent name={userName || "Dein Name"} />
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
              <div></div>
            </span>
          </>
        )}
        <h1 id="collaboration" className="mt-16 mb-2 text-4xl font-bold leading-none text-center">
          <FloatingHeadline>{t("collaboration")}</FloatingHeadline>
        </h1>
        {isError && (
          <div className="mt-4 flex justify-center items-center h-screen">
            <h3>
              <div className="alert alert-error font-bold">
                <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} /> Error while fetching tiles
              </div>
            </h3>
          </div>
        )}

        <div className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {isLoading
            ? Array.from({ length: getRandomCount(2, 5) }).map((_, index) => <SkeletonTile key={index} />)
            : collaborationTiles.map(tile => (
                <Tile
                  uuid={tile.uuid}
                  key={tile.name}
                  href={tile.href}
                  logoSrc={tile.logoSrc}
                  isNew={tile.isNew}
                  requestActionName={tile.requestActionName}
                  badgeText={tile.badgeText}
                  badgeType={tile.badgeType}
                />
              ))}
        </div>

        <h1 id="bookings" className="mt-16 mb-2 text-4xl font-bold leading-none text-center">
          <FloatingHeadline>{t("bookings")}</FloatingHeadline>
        </h1>
        {isError && (
          <div className="mt-4 flex justify-center items-center h-screen">
            <h3>
              <div className="alert alert-error font-bold">
                <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} /> Error while fetching tiles
              </div>
            </h3>
          </div>
        )}

        <div className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {isLoading
            ? Array.from({ length: getRandomCount(2, 5) }).map((_, index) => <SkeletonTile key={index} />)
            : bookingsTiles.map(tile => (
                <Tile
                  uuid={tile.uuid}
                  key={tile.name}
                  href={tile.href}
                  logoSrc={tile.logoSrc}
                  isNew={tile.isNew}
                  requestActionName={tile.requestActionName}
                  badgeText={tile.badgeText}
                  badgeType={tile.badgeType}
                />
              ))}
        </div>

        <h1 id="benefits" className="mt-16 mb-2 text-4xl font-bold leading-none text-center">
          <FloatingHeadline>{t("benefits")}</FloatingHeadline>
        </h1>
        {isError && (
          <div className="mt-4 flex justify-center items-center h-screen">
            <h3>
              <div className="alert alert-error font-bold">
                <FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} /> Error while fetching tiles
              </div>
            </h3>
          </div>
        )}

        <div className="grid lg:grid-cols-5 md:grid-cols-2 sm:grid-cols-1 gap-4">
          {isLoading
            ? Array.from({ length: getRandomCount(2, 5) }).map((_, index) => <SkeletonTile key={index} />)
            : benefitsTiles.map(tile => (
                <Tile
                  uuid={tile.uuid}
                  key={tile.name}
                  href={tile.href}
                  logoSrc={tile.logoSrc}
                  isNew={tile.isNew}
                  requestActionName={tile.requestActionName}
                  badgeText={tile.badgeText}
                  badgeType={tile.badgeType}
                />
              ))}
        </div>
      </div>
    </>
  );
}
